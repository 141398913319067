.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.active-scroll-spy {
  border-radius: 15px;
  transition: all 0.5s;
}
.ss-item {
  color: black;
  padding: 30px;
  margin: 10px;
}

.button {
  bottom: 0;
  right: 0;
  position: fixed;
  background-color: beige;
  border-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.button-size {
  margin: 30px;
  font-size: 20px;
  padding: 25px 40px;
}

a {
  text-decoration: none;
}
/* media query for screens less than 500px */
@media screen and (max-width: 500px) {
  .ss-item {
    padding: 17px;
  }
  .button-size {
    margin: 20px;
    font-size: 17px;
    padding: 15px 25px;
  }
}

/* demo 2 */
/* .ss-item-demo-2 {
  opacity: 0;
} */

.ss-active-demo-2 {
  transition: all s;
  opacity: 100%;
}
